import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import PageStandard from "Components/Page";
import PageTitle from "Components/PageTitle";
import PageDescription from "Components/PageDescription";
import { infoServicesHotel as data, applications } from "./datahotels.js";
import "./Tryton.css";
import dataModules from "Home/data";
import PageHeroStandard from "Components/PageHeroStandard";
import imgHoteles from "../Assets/images/imagenbanner.png";
import imgHotelesResponsive from "../Assets/images/responsive-hoteleria.png";
import imgSoftwareHotel from "../Assets/images/apps/software-de-hoteleria-integrado.png";
import software_erp from "../Assets/images/software-erp.png";
import backgroud from "../Assets/images/split_background.svg";
import banner from "../Assets/images/banner.png";
import german from "../Assets/images/german.png";
import Aos from "aos";
import "aos/dist/aos.css";
import Section from "Components/Section";
import {
  BriefcaseIcon,
  ChartBarSquareIcon,
  ChartPieIcon,
  ChatBubbleLeftRightIcon,
  CheckIcon,
  ChevronDoubleDownIcon,
  ComputerDesktopIcon,
  CreditCardIcon,
  CursorArrowRippleIcon,
  PhoneArrowDownLeftIcon,
  StarIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/solid";
import CardGraph from "Components/CardGraph";
import GallerySection from "Home/GallerySection.";
import OtherModules from "Home/OtherModules";
import FormModal from "Components/modal/FormModal";
import ImgGrap from "Assets/images/graficachar.png";
import ImgGrapPie from "Assets/images/grapiechar.png";
import ImgGrapPrediccion from "Assets/images/graphitprediccion.png";
import ImgPms from "Assets/images/software-eps.png";
import ImgPos from "Assets/images/software-pos.png";
import ImgCrm from "Assets/images/software-crm.png";
import SectionTypeService from "Components/pages/SectionTypeService";
import SectionTypeServicePopUp from "Components/pages/SectionTypeServicePopUp.js";
import { Helmet } from "react-helmet";

const STYLE_TITLE_FEATURE =
  "font-lato text-bg-bluePresik  lg:text-xl uppercase text-gra-600 font-semibold text-center";
const STYLE_TEXT_FEATURE =
  "text-gray-600 text-base hidden md:block font-roboto text-center";
const STYLE_ICON_FEATURE =
  " text-bg-bluePresik  rounded-full z-30 relative mx-auto";

function PageHoteles() {
  const device = window.innerWidth;
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const Features = () => {
    return (

      <div>
        
        <h2 className="font-lato font-semibold lg:text-3xl text-2xl lg:w-full relative text-bg-bluePresik leading-none max-w-2xl mx-auto uppercase text-center mb-10">
          {" "}
          Integraciones        </h2>
        <div className="grid md:grid-cols-3 place-items-center md:divide-x space-y-8 md:space-y-0 divide-bg-bluePresik divide-opacity-70">
          <div className="px-16 flex flex-col justify-center space-y-2">
            <span className={STYLE_ICON_FEATURE}>
              <CreditCardIcon className="w-10" />
              {/* <LineChart /> */}
            </span>
            <h2 className={STYLE_TITLE_FEATURE}>Channel Manager:</h2>
            <p className={STYLE_TEXT_FEATURE}>
              Gestiona reservas en múltiples canales en tiempo real desde una
              única plataforma.
            </p>
          </div>
          <div className="px-16 flex flex-col justify-center space-y-2">
            <span className={STYLE_ICON_FEATURE}>
              <ComputerDesktopIcon className="w-10" />
            </span>
            <h2 className={STYLE_TITLE_FEATURE}>Pasarelas de Pagos: </h2>
            <p className={STYLE_TEXT_FEATURE}>
              Procesa pagos en línea de forma segura y rápida, integrando las
              principales pasarelas.
            </p>
          </div>
          <div className="px-16 flex flex-col justify-center space-y-2">
            <span className={STYLE_ICON_FEATURE}>
              <CursorArrowRippleIcon className="w-10" />
            </span>
            <h2 className={STYLE_TITLE_FEATURE}>Web site del Hotel</h2>
            <p className={STYLE_TEXT_FEATURE}>
              Personaliza tu sitio web para atraer clientes y facilitar reservas
              en línea.
            </p>
          </div>
        </div>
      </div>
    );
  };

  const WhatDoWeDo = () => {
    return (
      <Fragment>
        <h2 className="mx-auto upe max-w-4xl lg:text-6xl mb-10 text-center font-semibold text-4xl uppercase font-lato">
          Inteligencia de Negocios
        </h2>
        <p className="lg:text-2xl text-lg  font-light pb-10 md:pb-2 text-center md:mb-8 max-w-6xl mx-auto font-roboto">
          Hemos desarrollado tecnología aplicada para hoteles, con el fin de
          mejor el rendimiento de los establecimientos de alojamiento a través
          de:
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 place-items-stretch gap-x-16 gap-y-4 md:gap-y-0 items-stretch justify-between">
          <CardGraph
            title="Set Competitivo"
            desc="Sube las reservas con un sistema facil y rapido"
          >
            <img src={ImgGrap} className="w-full" alt= "Set competitivos para hoteleros " />
          </CardGraph>
          <CardGraph
            title="Data Events"
            desc="Analiza los precios para determinar la tarifa"
            style="bg-gray-100 !text-bg-bluePresik"
          >
            <img src={ImgGrapPie} className="w-[110%]" alt= "Kpis de hoteles " />
          </CardGraph>
          <CardGraph
            title="Pronósticos"
            desc="Con base al porcentaje histórico, se pronostica su ocupación en el futuro"
            style="bg-gray-100 !text-bg-bluePresik"
          >
            <img src={ImgGrapPrediccion} className="w-full mb-14" alt="inteligencia artificial para hoteles" />
          </CardGraph>
        </div>
        <div className="grid md:grid-cols-2 gap-x-20 mt-20">
          <div className="space-y-5 mb-10 md:mb-0">
            <h2 className="mx-auto max-w-3xl lg:text-4xl  font-semibold text-4xl uppercase font-lato">
              MUCHAS EMPRESAS NOS PREFIEREN COMO SU PROVEEDOR TECNOLOGICO?
            </h2>
            <p className="lg:text-xl text-lg  font-light pb-10 md:pb-2 text-left md:mb-8 max-w-6xl mx-auto font-roboto">
              simplifica la gestión hotelera con una interfaz fácil de usar,
              integración con canales de distribución y análisis avanzado para
              decisiones eficaces.
            </p>
            <ul className="md:space-y-7 space-y-4">
              <li className="flex items-center space-x-6 font-roboto  font-light text-lg">
                <CheckIcon className="w-12 p-2 bg-gray-100 rounded-md shadow-sm" />{" "}
                <span>
                  <span className="block text-2xl font-medium">
                    Optimización Total
                  </span>{" "}
                  Simplifica la gestión de reservas y operaciones con una
                  interfaz intuitiva.
                </span>
              </li>
              <li className="flex items-center space-x-6 font-roboto  font-light text-lg">
                <CheckIcon className="w-12 p-2 bg-gray-100 rounded-md shadow-sm" />{" "}
                <span>
                  <span className="block text-2xl font-medium">
                    Integración Eficiente
                  </span>{" "}
                  Conecta con múltiples canales de distribución para maximizar
                  tu alcance.
                </span>
              </li>
              <li className="flex items-center space-x-6 font-roboto  font-light text-lg">
                <CheckIcon className="w-12 p-2 bg-gray-100 rounded-md shadow-sm" />{" "}
                <span>
                  <span className="block text-2xl font-medium">
                    Análisis Avanzado
                  </span>{" "}
                  Ofrece herramientas de análisis para tomar decisiones basadas
                  en datos.
                </span>
              </li>
            </ul>
          </div>
          <div className="rounded-md bg-cover  h-fit overflow-hidden shadow-md">
            <img src={banner} className="w-full" alt= "Tecnología para hoteles" />
          </div>
        </div>
      </Fragment>
    );
  };

  const ContentApp = ({ data, position }) => {
    const { image, title, desc, features } = data;
    const reverse = data.id % 2 == 0 ? true : false;
    return (
      <Section
        bgColor={reverse ? "md:flex-row-reverse md:bg-white " : "bg-gray-100"}
        spaceTop="py-10 md:pt-16 h-max"
      // fullContainer={true}
      // overFlow={true}
      >
        <div
          className={`md:flex md:gap-x-20 z-20  ${reverse ? "flex-row-reverse bg-white" : ""
            }`}
        >
          <div className="md:space-y-5 md:w-1/2">
            <h2 className="mx-auto max-w-3xl lg:text-4xl  font-semibold text-4xl uppercase font-lato">
              {title}
            </h2>
            <p className="lg:text-xl text-lg  font-light pb-10 md:pb-2 text-left mb-0 max-w-6xl mx-auto font-roboto">
              {desc}
            </p>
            <ul className=" space-y-5 md:space-y-7">
              {features &&
                features.map((item, key) => (
                  <li
                    key={key}
                    className="flex items-start md:space-x-6 space-x-3 font-roboto font-light text-lg"
                  >
                    <span>
                      <CheckIcon
                        className={`"w-7 h-7 md:w-12 md:h-12 p-1  md:p-2 ${reverse ? "bg-gray-100" : "bg-white"
                          } rounded-md shadow-sm"`}
                      />
                    </span>
                    <span>
                      <span className="block text-2xl font-medium">
                        {item.title}
                      </span>{" "}
                      {item.desc}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
          <div className="rounded-md bg-cover md:w-1/2 h-fit flex items-start ">
            <img
              src={image}
              className="w-full h-full scale-110 md:-top-16 relative hover:scale-150 duration-150 transition-all transform"
            />
          </div>
        </div>
      </Section>
    );
  };
  const dataFilterIcons = () => {
    const dataIcons = dataModules
      .map((objeto) => {
        const { icon, color } = objeto;
        return { icon, color };
      })
      .slice(0, 4);

    return dataIcons;
  };

  const ViewApplications = () => {
    return (
      <Fragment>
        {applications &&
          applications.map((item, i) => (
            <ContentApp data={item} position={i} />
          ))}
      </Fragment>
    );
  };

  const Testimonials = () => {
    const Starts = () => {
      const starArray = Array(5).fill(null);

      return (
        <div className="flex space-x-2">
          {starArray.map((_, index) => (
            <StarIcon key={index} className="w-7 text-yellow-500 " />
          ))}
        </div>
      );
    };
    return (
      <div className="md:flex md:h-80 -space-y-10 md:space-x-10">
        <div className="rounded-xl overflow-hidden md:w-[30%] relative flex justify-center items-center group -z-10">
          <div className=" absolute left-0 top-0 h-full w-full" />

          <a
            className="absolute cursor-pointer"
            href="https://www.youtube.com/watch?v=w7VkV15vmwM"
            target="_blank"
          >
            <VideoCameraIcon className="w-14   text-white" />
          </a>

          <img
            src={german}
            className="h-full w-full object-cover  transition-all duration-200 "
          />
        </div>
        <div className="bg-bluePresik text-white rounded-xl md:w-[70%] flex flex-col items-center justify-center p-6 mt-4 md:mt-0 md:p-16 z-20 mx-2 md:mx-0">
          <p className="md:text-2xl">
            "¡Estamos emocionados de compartir cómo el restaurante Palo e Mango
            ha transformado su gestión con Presik!.
            <br />
            Antes, luchaban con varios sistemas de software que no estaban a la
            altura de sus expectativas. 😓 Pero ahora, con nuestra solución
            integrada, han simplificado su operación y maximizado su eficiencia.
            "
          </p>
          <div className="md:flex justify-between items-center w-full mt-4">
            <div className="flex flex-col">
              <span className="text-yellow-500 text-xl font-medium">
                Gelbert Garcia
              </span>
              <span className="text-white font-light">
                Ingeniero Industrial - Especialista en Alta Gerencia
              </span>
            </div>
            <Starts />
          </div>
        </div>
      </div>
    );
  };
  const ContentBookingEngine = () => {
    return (
      <div className="container mx-auto min-h-[800px] z-30 px-2 md:px-0">
        <div className="md:w-1/2 flex flex-col justify-center h-full space-y-5">
          <h2 className=" font-lato pt-12 md:mt-8  lg:text-5xl text-4xl uppercase font-semibold text-white text-left ">
            <span className="text-yellow-300 font-extrabold">
              ¡Optimiza tus Reservas!
            </span>{" "}
            Motor de Reservaciones Eficiente para Hoteles
          </h2>
        

          <ul className="font-roboto lg:text-xl text-xl font-normal text-left text-white space-y-4">
            <li>
              <h3 className="text-yellow-300">
                Integración a Pasarelas de Pagos
              </h3>
              <p className="font-light text-base">
                Facilita el proceso de pago con integración directa a las
                principales pasarelas de pagos, asegurando transacciones seguras
                y rápidas.
              </p>
            </li>
            <li>
              <h3 className="text-yellow-300">Disponibilidad en Tiempo Real</h3>
              <p className="font-light text-base">
                Muestra las reservas y habitaciones disponibles en tiempo real,
                evitando sobreventas y garantizando precisión en la información.
              </p>
            </li>
            <li>
              <h3 className="text-yellow-300">Carrito de Pagos Intuitivo</h3>
              <p className="font-light text-base">
                Ofrece un carrito de pagos fácil de usar, permitiendo una
                gestión rápida y sencilla de las reservas.
              </p>
            </li>
            <li>
              <h3 className="text-yellow-300">
                Precios Personalizados por Tipo de Acomodación
              </h3>
              <p className="font-light text-base">
                Permite definir diferentes precios para cada tipo de
                acomodación, adaptándose a las necesidades específicas de tu
                hotel.
              </p>
            </li>
          </ul>

          {/* <div>
          logos
        </div> */}
        </div>
      </div>
    );
  };

  const SectionAppsWeb = () => {
    return (
      <div
        id="modules-section"
        className="md:pt-10 flex flex-col justify-center mb-0 pb-10 pt-10  lg:pb-10 lg:px-0 px-5  mx-auto  p-2 bg-bluePresik w-full"
      >
        <h2 className="font-lato text-white lg:text-6xl text-4xl uppercase font-semibold text-center">
          Aplicativos Web
        </h2>
        <p className="font-roboto lg:text-2xl text-lg text-white font-light md:pb-2 text-center md:mt-8 mt-5 max-w-6xl mx-auto">
          Hemos desarrollado aplicativos web para ofrecerte un manejo más eficiente de tu información desde cualquier dispositivo, facilitando la gestión y el acceso en todo momento.
        </p>

        <ChevronDoubleDownIcon className="w-10 mx-auto text-white animate-pulse mt-4" />
      </div>
    );
  };

  return (
    <PageStandard fullWidth={true} overFlow={true} >
        
        <Helmet>
        <title>▷ software hoteles más completa del mercado en Colombia【2024】</title>
        <meta name="description" content="El software para hoteles más completa del mercado en Colombia, el pms de gestión de reservas más eficiente y sencilla del sector." />
        <meta name="keywords" content="software hoteles" />
        <meta property="og:title" content="Software para hoteles"/>
        <meta property="og:description" content={t("El software para hoteles más completa del mercado en Colombia, el pms de gestión de reservas más eficiente y sencilla del sector.")} />
        <meta property="og:image" content={imgHoteles} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="software.hoteles" />
        <meta name="twitter:description" content={t("solutions.desc-hotel")} />
        <meta name="twitter:image" content={imgHoteles} />
         <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: t("solutions.hoteles"),
            description: t("solutions.desc-hotel"),
            image: imgHoteles,
            url: window.location.href,
            potentialAction: {
              "@type": "SearchAction",
              target: `${window.location.href}?q={search_term_string}`,
              "query-input": "required name=search_term_string"
            }
          })}
        </script>
        </Helmet>
      <PageHeroStandard
        backgroundHero="heroService"
        image={device > 600 ? imgHoteles : imgHotelesResponsive}
        data={dataFilterIcons()}
        divide={false}
      >
        <PageTitle title="solutions.hoteles" textAling="text-left" />
        <PageDescription text="solutions.desc-hotel" textAling="text-left" />
        <FormModal />
      </PageHeroStandard>
      {/* container */}

      <Section
        fullContainer={true}
        spaceTop="pt-0 z-0 mt-9  md:pt-0"
        bgColor="bg-gray-100"
        overFlow={true}
        spaceX="px-0"
      >
        <SectionTypeServicePopUp
          data={data["pms"]}
          image={ImgPms}
          title={"PMS"}
          desc={"Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
        />
      </Section>
      <Section fullContainer={true} spaceTop="pt-0 mt-0  md:pt-0" overFlow={true} spaceX="px-0" >
        <SectionTypeServicePopUp
          data={data["erp"]}
          image={software_erp}
          title={"ERP"}
          desc={"Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
          position="right"
        />
      </Section>
      <Section
        fullContainer={true}
        spaceTop="pt-0 mt-0  md:pt-0"
        bgColor="bg-gray-100"
        overFlow={true}
        spaceX="px-0"
      >
        <SectionTypeServicePopUp
          data={data["pos"]}
          image={ImgPos}
          title={"POS"}
          desc={"Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
          overFlow={true}
        />
      </Section>
      <Section fullContainer={true} spaceTop="pt-0 mt-0  md:pt-0" spaceX="px-0">
        <SectionTypeServicePopUp
          data={data["crm"]}
          image={ImgCrm}
          title={"CRM"}
          desc={"Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
          position="right"
          overFlow={true}
        />
      </Section>

      <Section bgColor="bg-gray-100" spaceTop="py-10 md:py-16">
        <Features />
      </Section>
      <Section spaceTop="py-10 md:py-24">
        <WhatDoWeDo />
      </Section>
      <SectionAppsWeb />

      <ViewApplications />
      <Section spaceTop="py-10 md:py-10" bgColor="bg-bluePresik" fullContainer={true}>
        <ContentBookingEngine />
        <img
          src={imgSoftwareHotel}
          className="w-full h-full absolute left-0 top-0 object-cover -z-10"
        />
      </Section>
      <Section spaceTop="py-10 md:pb-24" overFlow={true} spaceX="px-0">
        <h2 className="mx-auto py-3 max-w-2xl lg:text-6xl mb-10 text-center font-semibold text-4xl uppercase font-lato px-2 md:px-0">
          ¿Que dicen nuestros clientes?
        </h2>

        <Testimonials />
      </Section>
    </PageStandard>
  );
}

export default PageHoteles;
